/* eslint-disable no-self-compare */
import './App.css';
import "./global.css"
import Plx from "react-plx";
import happy_birthday from './assets/happy_birthday.png';
import dinh2 from './assets/dinh2.png';
import duaHong from './assets/dua_hong.png';
import moto from './assets/moto.png';
import shibaDog from './assets/shiba-dog.png';
import tiengTauKeu2 from './assets/tieng_tau_bi_loi_di.png';
import clickVaoTauDi2 from './assets/click_vao_tau_di_2.png';
import layer6 from './assets/layer-06.png';
import { IParallax, Parallax, ParallaxLayer } from "@react-spring/parallax";
import React, { useState, useEffect, useRef } from 'react';
import useSound from "./hooks/useSound"
import sounds from './assets/audio';
import { bidaer, chuyen_gia_pha_bi, cung_quay_nao, dinhBowling, dinhCosplay, happy_birthday_2, nhang_muoi, thich_pha_theo, tra_co_nuong, tuyen } from './assets/images';
import ModalVideo from 'react-modal-video';
import { chuc_mung_sinh_nhat_kalimba, chuyen_gia_pha_bi_video, dinhBest, dinhBidaer, hoiNhieuChuyenVideo, thich_pha_theo_video, tra_co_nuong_video, party, kalimba } from './assets/video/index';
import { hoiNhieuChuyen, dai_su, thich_an_anh } from './assets/images';

import toast, { Toaster } from 'react-hot-toast';
import useLocalStorage from './hooks/useLocalStorage';

const BirthDay = () => (
  <ParallaxLayer
    offset={0}
    speed={1}
    factor={1}
    style={{
      backgroundImage: `url(${happy_birthday})`,
      backgroundSize: 'contain',
      backgroundPosition: 'top'
    }}
  ></ParallaxLayer>
)

const Star = () => (
  <ParallaxLayer
    offset={0}
    speed={1}
    factor={1}
    style={{
      backgroundImage: `url(${layer6})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }}
  ></ParallaxLayer>
)

const url = (name: string, wrap = false) => {
  const url = `${wrap ? 'url(' : ''}https://awv3node-homepage.surge.sh/build/assets/${name}.svg${wrap ? ')' : ''}`
  console.log('url: ', url);
  return url;
}



const BIRTHDAY = '12-11';
// const BIRTHDAYS = '12-11';
const BACKGROUND_COLOR = "#3E3E3E";
const { height, width } = window.screen;

function App() {

  const {status, setStatus} = useSound(sounds.dogSound);
  const [videoUrl, setVideoUrl] = useState(false);

  let element = useRef(null);
  const [buttonMessage, setButtonMessage] = useState('Down')
  const [isOpen, setIsOpen] = useState(false);

  const [password, setPassword] = useLocalStorage("PASSWORD", "");

  useEffect(() => {
    if(videoUrl){
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [videoUrl])


  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // console.log('e: ', e);
      // console.log('do validate');
      const birthdayInput = e.target.value;
      // console.log('birthday: ', birthdayInput);
      if(birthdayInput !== BIRTHDAY){
        toast.error("Sai Password rồi bạn ei :))")
        // console.log("Sai Password rồi bạn ei :))");
      } else {
        setPassword(birthdayInput)
      }
    }
  }

  if(!password || password !== BIRTHDAY){
    return (
      <>
        <div style={{
          width: window.innerWidth,
          height: window.innerHeight,
          // backgroundColor: "red",
          display: 'flex',
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center"
        }}>
          <input style={{
            padding: 5,
            width: 380,
            fontSize: 20,
            borderColor: "#2d2d2d",
            borderWidth: '2px',
            // textAlign: "center",
          }} type="text"
          placeholder="Nhập ngày sinh DinDin - Tuyền (DD-MM)"
          onKeyDown={handleKeyDown}
          />
        </div>
        
      </>
    )
  }

  const handleClick = () => {
    // Change the button text to Down and
    // send the the user to the end of the page.
    if (buttonMessage === 'Down') {
      setButtonMessage('Up')
      element?.scrollTo(2)
    }
    // Change the button text to Up and
    // send the the user to the top of the page.
    if (buttonMessage === 'Up') {
      setButtonMessage('Down')
      element?.scrollTo(0)
    }
  }

  /*
    pages: chiều cao của page phần tử
    - speed: tốc độ scroll của phần tử
  */

  const parallaxData = [
    {
      start: 0,
      end: 800,
      properties: [
        {
          startValue: 1,
          endValue: 1.4,
          property: "scale",
        },
      ],
    },
  ];

  return (
    <>
    <div className={'w-full h-full'}>
      <Plx parallaxData={parallaxData}>
        <img src={happy_birthday} alt='birthday' />
      </Plx>
      <Plx className='absolute top-0 left-0' parallaxData={parallaxData}>
        <img src={layer6} alt='star' />
      </Plx>
    <div className='relative'>
      <Plx style={{
        top: 0,
        right: 0,
        zIndex: 3,
      }} parallaxData={[
        {
          start: 700,
          end: 1000,
          properties: [
            {
              startValue: 0,
              endValue: -1700,
              property: "translateX",
            },
          ],
        },
      ]}>
        <img src={dinh2} alt='dinh2' />
      </Plx>
      <Plx className='absolute top-0 left-1/2 w-4/12 opacity-0' parallaxData={[
        {
          start: 200,
          end: 500,
          properties: [
            {
              startValue: 0,
              endValue: 1,
              property: 'opacity',
            },
          ],
        },
        {
          start: 500,
          end: 600,
          properties: [
            {
              startValue: 1,
              endValue: 0,
              property: 'opacity',
            },
          ],
        },
      ]}>
        <img src={duaHong} alt='dua hong' />
      </Plx>
      <Plx style={{
        borderRadius: "20px",
        backgroundColor: BACKGROUND_COLOR,
      }} className='p-3 font-bold absolute left-1/4 top-1/3 w-3/12 opacity-0 bg-red-600' parallaxData={[
        {
          start: 100,
          end: 200,
          properties: [
            {
              startValue: 0,
              endValue: 1,
              property: 'opacity',
            },
          ],
        },
        {
          start: 450,
          end: 700,
          properties: [
            {
              startValue: 1,
              endValue: 0,
              property: 'opacity',
            },
          ],
        },
      ]}>
        <p className='text-white text-2xl'>
        Đả nữ chuyên thách thức những tay đua cừ khôi nhất.
        </p>
      </Plx>
      <Plx style={{
        zIndex: -1,
      }} className='absolute bottom-0 right-0' parallaxData={[
        {
          start: 0,
          end: 300,
          properties: [
            {
              startValue: 700,
              endValue: 700,
              property: 'translateX',
            },
          ],
        },
        {
          start: 300,
          end: 700,
          properties: [
            {
              startValue: 700,
              endValue: 200,
              property: 'translateX',
            },
          ],
        },
        {
          start: 700,
          end: 1000,
          properties: [
            {
              startValue: 200,
              endValue: -1500,
              property: 'translateX',
            },
          ],
        },
      ]}>
        <img width={"720px"} height={"390px"} src={moto} alt='moto' />
      </Plx>
    </div>
    <div style={{
        height: '570px',
        backgroundColor: BACKGROUND_COLOR,
      }} className='relative bg-green-600'
    >
      <p className='m-4 mr-24 text-white text-2xl font-bold p-3 w-1/3 absolute right-0 top-1/3 rounded-2xl bg-slate-400'>
        Sau đó bạn sẽ chỉ còn thấy tiếng nẹt pô và khói xe nghi ngút... và tiếng chó kêu văng vẳng đâu đây
      </p>
      <img style={{ position: "absolute", top: 150, right: window.innerWidth/2}}  width="292px" height="406px" src={shibaDog} alt="shiba dog" onClick={() => setStatus()} />
      {status !== "play" ?<img style={{ position: "absolute", top: 0, left: window.innerWidth/12}} width="300" height="230" src={clickVaoTauDi2} alt="shiba dog"/>
      :<img style={{ position: "absolute", top: 0, left: window.innerWidth/12 }} width="300" height="230" src={tiengTauKeu2} alt="shiba dog"/>
      }
      </div>
      <div style={{ height: "630px" }} className='relative bg-green-600 flex flex-row'>
        <div className='flex group cursor-pointer w-1/4
        bg-slate-400 overflow-hidden relative' onClick={() => {
          setVideoUrl(dinhBest)
        }}>
          <img alt="dinh001" className='w-full opacity-50 hover:opacity-100 hover:scale-105 duration-1000' src={dinhBowling} />
          <h2 className='opacity-0 group-hover:opacity-100 text-cyan-50 duration-500 bold text-4xl text-center absolute bottom-10 left-1/2 -translate-x-1/2'>
            CÔNG TÚA BOWLING
          </h2>  
        </div>
        <div className='flex group cursor-pointer w-1/4
        bg-slate-400 overflow-hidden relative' onClick={() => {
          setVideoUrl(hoiNhieuChuyenVideo)
        }}>
          <img alt="dinh001" src={hoiNhieuChuyen} 
            className='w-full opacity-50 hover:opacity-100 hover:scale-105 duration-1000' />
          <h2 className='opacity-0 group-hover:opacity-100 text-cyan-50
            duration-500 bold text-4xl text-center absolute bottom-10
            left-1/2 -translate-x-1/2'>
            HỘI NHIỀU CHIỆN
          </h2>  
        </div>
        <div className='flex group cursor-pointer w-1/4
        bg-slate-400 overflow-hidden relative' onClick={() => {
          setVideoUrl(dinhBidaer)
        }}>
          <img alt="dinh001" src={bidaer} 
            className='w-full opacity-50 hover:opacity-100 hover:scale-105 duration-1000' />
          <h2 className='opacity-0 group-hover:opacity-100 text-cyan-50
            duration-500 bold text-4xl text-center absolute bottom-10
            left-1/2 -translate-x-1/2'>
            CAO THỦ BI DA ẨN DẬT
          </h2>  
        </div>
        <div className='flex group w-1/4
        bg-slate-400 overflow-hidden relative' onClick={() => {
          // setVideoUrl(hoiNhieuChuyenVideo)
        }}>
          <img alt="dinh001" src={dinhCosplay} 
            className='w-full opacity-50 hover:opacity-100 hover:scale-105 duration-1000' />
          <h2 className='opacity-0 group-hover:opacity-100 text-cyan-50
            duration-500 bold text-4xl text-center absolute bottom-10
            left-1/2 -translate-x-1/2'>
            "THÍCH COSPLAY"
          </h2>  
        </div>
      </div>
      <div className='relative' style={{
        height: 780,
      }}>
        <Plx parallaxData={[
          {
            start: 0,
            end: 2400,
            properties: [
              {
                startValue: -600,
                endValue: -600,
                property: "translateX",
              },
            ],
          },
          {
            start: 2400,
            end: 2800,
            properties: [
              {
                startValue: -600,
                endValue: 0,
                property: "translateX",
              },
            ],
          },
        ]}>
          <img src={tuyen} alt='tuyen' />
        </Plx>
        <Plx className='absolute right-0 bottom-1/2 translate-y-1/2 translate-x-1/2' parallaxData={[
          {
            start: 0,
            end: 2400,
            properties: [
              {
                startValue: 500,
                endValue: 500,
                property: "translateX",
              },
            ],
          },
          {
            start: 2600,
            end: 2800,
            properties: [
              {
                startValue: 500,
                endValue: -100,
                property: "translateX",
              },
            ],
          },
        ]}>
          <img className='scale-150' src={nhang_muoi} alt='tuyen' />
        </Plx>
        <Plx className='absolute right-1/3 bottom-1/3' parallaxData={[
          {
            start: 0,
            end: 2400,
            properties: [
              {
                startValue: 0,
                endValue: 0,
                property: "opacity",
              },
            ],
          },
          {
            start: 2550,
            end: 2800,
            properties: [
              {
                startValue: 0,
                endValue: 1,
                property: "opacity",
              },
            ],
          },
        ]}>
          <img className='scale-50' src={dai_su} alt='dai su' />
        </Plx>
      </div>
      <div style={{ height: "630px" }} className='relative bg-green-600 flex flex-row'>
        <div className='flex group cursor-pointer w-1/4
        bg-slate-400 overflow-hidden relative' onClick={() => {
          setVideoUrl(tra_co_nuong_video)
        }}>
          <img alt="dinh001" className='w-full opacity-50 hover:opacity-100 hover:scale-105 duration-1000' src={tra_co_nuong} />
          <h2 className='opacity-0 group-hover:opacity-100 text-cyan-50 duration-500 bold text-4xl text-center absolute bottom-10 left-1/2 -translate-x-1/2'>
            "T-RÀ" CÔ NƯƠNG
          </h2>  
        </div>
        <div className='flex group cursor-pointer w-1/4
        bg-slate-400 overflow-hidden relative' onClick={() => {
          setVideoUrl(chuyen_gia_pha_bi_video)
        }}>
          <img alt="dinh001" src={chuyen_gia_pha_bi} 
            className='w-full opacity-50 hover:opacity-100 hover:scale-105 duration-1000' />
          <h2 className='opacity-0 group-hover:opacity-100 text-cyan-50
            duration-500 bold text-4xl text-center absolute bottom-10
            left-1/2 -translate-x-1/2'>
            CHUYÊN GIA PHÁ BI
          </h2>  
        </div>
        <div className='flex group cursor-pointer w-1/4
        bg-slate-400 overflow-hidden relative' onClick={() => {
          setVideoUrl(thich_pha_theo_video)
        }}>
          <img alt="dinh001" src={thich_pha_theo} 
            className='w-full opacity-50 hover:opacity-100 hover:scale-105 duration-1000' />
          <h2 className='opacity-0 group-hover:opacity-100 text-cyan-50
            duration-500 bold text-4xl text-center absolute bottom-10
            left-1/2 -translate-x-1/2'>
            THÍCH PHÁ THEO
          </h2>  
        </div>
        <div className='flex group w-1/4
        bg-slate-400 overflow-hidden relative' onClick={() => {
          // setVideoUrl(hoiNhieuChuyenVideo)
        }}>
          <img alt="thich_an_anh" src={thich_an_anh} 
            className='w-full opacity-50 hover:opacity-100 hover:scale-105 duration-1000' />
          <h2 className='opacity-0 group-hover:opacity-100 text-cyan-50
            duration-500 bold text-4xl text-center absolute bottom-10
            left-1/2 -translate-x-1/2'>
            "THÍCH ĂN ẢNH"
          </h2>  
        </div>
      </div>
      {/* <div className='bg-blue-700 w-full h-500' style={{ height }}>
        CHÚC MỪNG SINH NHẬT
      </div> */}
      <Plx className='right-1/2 bottom-1/2 bg-yellow-400' parallaxData={[
        {
          start: 0,
          end: 3600,
          properties: [
            {
              startValue: 0,
              endValue: 0,
              property: "opacity",
            },
          ],
        },
        {
          start: 3600,
          end: 4000,
          properties: [
            {
              startValue: 0,
              endValue: 1,
              property: "opacity",
            },
          ],
        },
      ]}
      onClick={() => {
        // setVideoUrl(chuc_mung_sinh_nhat_kalimba)
        setVideoUrl(kalimba)
      }}
      >
        <img className='scale-200 cursor-pointer' src={happy_birthday_2} alt='dai su' />
      </Plx>
      
      <Plx className='right-1/2 bottom-1/2 bg-yellow-400' parallaxData={[
        {
          start: 0,
          end: 3600,
          properties: [
            {
              startValue: 0,
              endValue: 0,
              property: "opacity",
            },
          ],
        },
        {
          start: 3600,
          end: 4000,
          properties: [
            {
              startValue: 0,
              endValue: 1,
              property: "opacity",
            },
          ],
        },
      ]}
      onClick={() => {
        setVideoUrl(party)
      }}
      >
        <img className='scale-200 cursor-pointer' src={cung_quay_nao} alt='dai su' />
      </Plx>

    </div>
    {videoUrl &&
      <>
        <div className='model-video-wrap' style={{
        }}>
          <ModalVideo
            ratio='16:9'
            channel="custom"
            url={videoUrl}
            autoplay
            isOpen={true}
            onClose={() => setVideoUrl(false)}
          />
        </div>
      </>
    }
    <Toaster />
    </>
  );
}

export default App;
