export const dinhBowling = require("./dinh/bowling.png");
export const dinhCosplay = require("./dinh/thich_cosplay.jpg");
export const hoiNhieuChuyen = require("./dinh/hoi_nhieu_chuyen.png");
export const bidaer = require("./dinh/bidaer.png");
export const tuyen = require("./tuyen/tuyen.png");
export const nhang_muoi = require("./tuyen/nhang_muoi.png");
export const dai_su = require("./tuyen/dai_su.png");
export const tra_co_nuong = require("./tuyen/tra_co_nuong.png");
export const chuyen_gia_pha_bi = require("./tuyen/chuyen_gia_pha_bi.png");
export const thich_pha_theo = require("./tuyen/thich_pha_theo.png");
export const thich_an_anh = require("./tuyen/thich_an_anh.png");
export const happy_birthday_2 = require("./happy_birthday_2.png");
export const cung_quay_nao = require("./cung_quay_nao.png");
// export const nhang_muoi = require("./tuyen/nhang_muoi.png");