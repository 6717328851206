import { useRef, useState, useEffect } from "react";
import icons from '../assets/images/icons/index';
import sounds from "../assets/audio";

const Sound = (sound) => {

  const refSound = useRef(new Audio(sound));
  const [status, setStatus] = useState(false);

  useEffect(() => {
    refSound.current.loop = true;
    refSound.current.volume = 0.1;
  }, []);

  useEffect(() => {
    if(status === 'play'){
      refSound.current.loop = true;
      refSound.current.play();
    } else  if(status === 'pause'){
      refSound.current.pause();
    }
  }, [status])

  const change = () => {
    const changeStatus = status === "play" ? "pause" : 'play';
    setStatus(changeStatus);
  }

  return {
    status,
    setStatus: change,
  }

  // return (
  //   <div style={{position: "fixed", top: 0, zIndex: 100, padding: '8px' }}>
  //     <img alt="sound" src={icons.sound} style={{
  //         width: 50,
  //         height: 50
  //       }}
  //       onClick={() => {
  //         const changeStatus = status === "play" ? "pause" : 'play';
  //         setStatus(changeStatus);
  //       }}
  //     />
  //     {/* <button onClick={() => (refSound.current.loop = false)}>Pause</button> */}
  //   </div>
  // );
};

export default Sound